import React from "react"
import Layout from "../components/layout"
import * as classes from "./wm.module.css"
import wmImage from "../data/images/washingmachine1.jpg"
import { Helmet } from "react-helmet"

export default function WashingMachine1() {
  return (
    <Layout>
      <Helmet>
        <title>Washing Machine 1</title>
      </Helmet>
      <main className={classes.main}>
        <section className={classes.grid}>
          <img
            src={wmImage}
            alt="Washing Machine"
            className={classes.wmImage}
          />
          <table>
            <tbody>
              <tr>
                <th colSpan={2}>Main Features</th>
              </tr>
              <tr>
                <td>Product group</td>
                <td>Washer Dryer</td>
              </tr>
              <tr>
                <th colSpan={2}>Technical Features</th>
              </tr>
              <tr>
                <td>Automatic Programmes</td>
                <td>Yes</td>
              </tr>
            </tbody>
          </table>
        </section>
        <table>
          <tbody>
            <tr>
              <th colSpan={2}>Performances</th>
            </tr>
            <tr>
              <td>
                Energy Efficiency Class Eco 40-60 programme (Regulation (EU)
                2017/1369)
              </td>
              <td>B</td>
            </tr>
          </tbody>
        </table>
      </main>
    </Layout>
  )
}
